export const drawWave = (normalizedData, canvasRef, setIsWaveDrawn) => {
    // set up the canvas
    const canvas = canvasRef.current;
    const dpr = window.devicePixelRatio || 1;
    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = canvas.offsetHeight * dpr;
    const ctx = canvas.getContext("2d");
    ctx.translate(0, canvas.height);
    ctx.scale(dpr, -dpr);
  
    // draw the line segments
    const width = canvas.offsetWidth / normalizedData.length;
    for (let i = 0; i < normalizedData.length; i++) {
      const x = width * i;
      
      let height = normalizedData[i] * canvas.offsetHeight;
      if (height < 0) {
          height = 0;
      }

      drawLineSegment(ctx, x, height, width);
    }

    setIsWaveDrawn(true);
};
  
/**
 * A utility function for drawing our line segments
 * @param {AudioContext} ctx the audio context 
 * @param {number} x  the x coordinate of the beginning of the line segment
 * @param {number} height the desired height of the line segment
 * @param {number} width the desired width of the line segment
 * @param {boolean} isEven whether or not the segmented is even-numbered
 */
const drawLineSegment = (ctx, x, height, width) => {
    ctx.lineWidth = 1; // how thick the line is
    ctx.strokeStyle = "#fff"; // what color our line is
    
    ctx.beginPath();
    ctx.moveTo(x, 0);

    if (height < width / 4) {
        ctx.lineTo(x + width, 0);
    } else {
        ctx.arc(x, width/4, width/4, 3*Math.PI/2, 0, false); // upward slope
        ctx.lineTo(x + width/4, height - width/4); // left side of wave
        ctx.arc(x + width/2, height - width/4, width / 4, Math.PI, 0, true); // semi circle at top
        ctx.lineTo(x + width * 3/4, width/4); // right side of wave
        ctx.arc(x + width, width/4, width/4, Math.PI, 3*Math.PI/2, false); // downward slope
    }

    ctx.stroke();
};

export const drawCounts = (bpm, offset, duration, canvasRef) => {
    const canvas = canvasRef.current;
    const dpr = window.devicePixelRatio || 1;
    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = canvas.offsetHeight * dpr;
    const ctx = canvas.getContext("2d");

    const countLines = getUpdatedCountLines(bpm, offset, duration, canvas.width);

    for (let i = 0; i < countLines.length; i++) {
        const x = countLines[i];
        const height = getCountLineHeight(i, canvas.height);

        ctx.lineWidth = 1;
        ctx.strokeStyle = "#fff";
    
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
    }
}

const getUpdatedCountLines = (bpm, offset, duration, width) => {
    const start = offset*width;
    const remainingSpace = width - start;
    const numBeats = bpm * duration / 60;
    const incrementalSpace = remainingSpace / numBeats;

    const xVals = [];
    for (let i = 0; i < numBeats; i++) {
        xVals.push(i * incrementalSpace + start);
    }
    
    return xVals;
};

const getCountLineHeight = (index, height) => {
    const count = (index + 1) % 8;

    if (count === 1) {
        return height;
    }

    if (count === 5) {
        return height / 2;
    }

    if (count === 3 || count === 7) {
        return height / 4;
    }

    return height / 8;
};
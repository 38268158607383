import React, { PureComponent } from 'react';
import './Pickers.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RepeatableButton from '../../../Common/RepeatableButton';

export default class NumberPicker extends PureComponent {
    onIncrement = () => {
        const newValue = this.props.value + this.props.step;

        this.props.onValueChange(newValue);
    }

    onDecrement = () => {
        const newValue = this.props.value - this.props.step;

        this.props.onValueChange(newValue);
    }

    render() {
        const { className, value, label, min, max, decimalPlaces, suffix } = this.props;
        
        const formattedValue = value.toFixed(decimalPlaces);

        return (
            <div className={`picker-wrapper ${className}`}>
                <div className='label'>
                    {label}
                </div>
                <div className='picker'>
                    <RepeatableButton
                        className={`increment ${value >= max ? 'disabled' : ''}`} 
                        onClick={this.onIncrement}>
                            <FontAwesomeIcon color="white" icon={'angle-up'} size="2x" />
                    </RepeatableButton>
                    <p className='number-display'>
                        {suffix
                            ? `${formattedValue} ${suffix}`
                            : formattedValue
                        }
                    </p>
                    <RepeatableButton
                        className={`decrement ${value <= min ? 'disabled' : ''}`} 
                        onClick={this.onDecrement}>
                            <FontAwesomeIcon color="white" icon={'angle-down'} size="2x" />
                    </RepeatableButton>
                </div>
            </div>
        );
    }
}
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Icon.scss';
import ScaleLoader from "react-spinners/ScaleLoader";
import { event, timing } from 'react-ga';

export default function Icon({ hasFile, onTryAgain }) {
    const [message, setMessage] = useState('Drag a file or click here');
    const [shouldShowButton, setShouldShowButton] = useState(false);

    let icon = null;

    if (!hasFile) {
        icon = <FontAwesomeIcon color="white" icon="file-upload" size="6x" />;
    } else {
        icon = <ScaleLoader color="white" height={56} width={6.4} />;
    }

    useEffect(() => {
        let timeoutId, initialTime;

        if (hasFile) {
            setMessage('Analyzing...');

            initialTime = performance.now();

            timeoutId = setTimeout(() => {
                setMessage('This is taking longer than it should...');
                setShouldShowButton(true);
            }, 10000);
        }

        return () => {
            clearTimeout(timeoutId);

            if (initialTime) {
                timing({
                    category: 'Performance',
                    variable: 'analyzing',
                    value: performance.now() - initialTime
                });
            }
        };
    }, [hasFile]);

    const onClick = () => {
        setMessage('Drag a file or click here');
        setShouldShowButton(false);

        event({
            category: "Clear",
            action: "Try again button",
        });

        onTryAgain();
    };

    return (
        <div className={`icon-wrapper ${shouldShowButton ? 'showing-button' : ''}`}>
            <div className="icon">
                {icon}
            </div>
            <div className="message">
                {message}
            </div>
            {shouldShowButton &&
                <button className='try-again' onClick={onClick}>
                    Try again
                </button>
            }
        </div>
    );
}
import React from 'react';

export default function WelcomeOverlay({ onClose }) {
    return (
        <div className='overlay'>
            <div className='modal'>
                <div className='modal-title'>Welcome to Korio</div>
                <div className='modal-subtitle'>The music player for dancers</div>
                <div className='modal-text'>
                    <div className='modal-subheader'>
                        Add the app!
                    </div>
                    <div>
                        From Chrome on Android devices
                    </div>
                    <div>
                        and from Safari on iOS devices,
                    </div>
                    <div>
                        tap <b>"Add to home screen"</b>
                    </div>
                    <div>
                        to use Korio offline!
                    </div>
                </div>
                <button className='close-button' onClick={onClose}>
                    Got it
                </button>
            </div>
        </div>
    );
}
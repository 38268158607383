import React, { createRef, PureComponent } from 'react';
import ProgressDisplay from './ProgressDisplay/ProgressDisplay';
import { getEightCountMarkers, getCount } from './MusicPlayerUtilities';
import { CANVAS_WIDTH } from '../Common/Constants';
import './MusicPlayer.scss';
import PlayerControls from './PlayerControls/PlayerControls';
import Overlay from './Overlay/Overlay';
import PlayerSettings from './PlayerSettings/PlayerSettings';
import localforage from 'localforage';

export default class MusicPlayer extends PureComponent {
    constructor(props) {
        super(props);

        this.audioRef = createRef();
        this.intervalId = null;
        this.first1Timeout = null;
        this.second1Timeout = null;
        this.needToInitializeForIos = true;

        this.state = {
            percentPlayed: 0,
            count: null,
            eightCountMarkers: getEightCountMarkers(props.bpm, props.offset, props.duration),
            isPlaying: false,
            isWaveDrawn: false,
            startTime: 0,
            endTime: props.duration,
            playbackRate: 1,
            isFiltering: false,
            shouldShowFirst1: false,
            shouldShowSecond1: false,
            loaded: false,
            url: null
        };
    }

    async componentDidMount() {
        document.addEventListener('keyup', this.respondToKeyUp);
        document.addEventListener('keydown', this.respondToKeyDown);
        document.addEventListener('touchstart', this.initializePlayer);

        // headphones controls
        if (navigator && navigator.mediaSession) {
            navigator.mediaSession.setActionHandler('play', this.onClickPlay);
            navigator.mediaSession.setActionHandler('pause', this.onClickPlay);
            navigator.mediaSession.setActionHandler('previoustrack', this.onClickSkipToStart);
            navigator.mediaSession.setActionHandler('nexttrack', this.onClickSkipToEnd);
            navigator.mediaSession.setActionHandler('seekbackward', this.onClickRewind);
            navigator.mediaSession.setActionHandler('seekforward', this.onClickForward);
        }

        try {
            this.defaults = await localforage.getItem('korio-defaults');

            if (!this.defaults) {
                this.defaults = {
                    offset: this.props.offset,
                    bpm: this.props.bpm,
                    startTime: 0,
                    endTime: this.props.duration,
                    playbackRate: 1,
                    isFiltering: false
                };
                localforage.setItem('korio-defaults', this.defaults);
            }

            const playbackRate = await localforage.getItem('korio-playback-rate');
            const isFiltering = await localforage.getItem('korio-is-filtering');
            const startTime = await localforage.getItem('korio-start-time');
            const endTime = await localforage.getItem('korio-end-time');

            if (playbackRate !== 1) {
                this.audioRef.current.playbackRate = playbackRate;
            }

            const fileToUse = isFiltering ? this.props.filteredFile : this.props.file;
            const url = URL.createObjectURL(fileToUse);

            this.setState({ 
                ...playbackRate !== null && { playbackRate },
                ...isFiltering !== null && { isFiltering },
                ...startTime !== null && { startTime },
                ...startTime !== null && { percentPlayed: startTime / this.props.duration },
                ...startTime !== null && { count: getCount(startTime, this.state.eightCountMarkers) },
                ...endTime !== null && { endTime },
                url,
                loaded: true
            });
        } catch (err) {
            console.log('loading error', err);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.respondToKeyUp);
        document.removeEventListener('keydown', this.respondToKeyDown);
        document.removeEventListener('touchstart', this.initializePlayer);
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.state.isPlaying && (this.state.endTime !== this.props.duration) && (this.audioRef.current.currentTime >= this.state.endTime)) {
            this.resetLoopToStart();
        }

        if (this.state.url !== prevState.url || this.state.isFiltering !== prevState.isFiltering) {
            this.audioRef.current.pause();

            this.audioRef.current.playbackRate = this.state.playbackRate;

            this.setTime(this.state.percentPlayed);
        }
    }

    resetLoopToStart = () => {
        this.onDragStartInternal();
        this.audioRef.current.currentTime = this.state.startTime;
        this.onClickPlayInternal();
    }

    initializePlayer = () => {
        if (this.needToInitializeForIos) {
            this.audioRef.current.play();
            this.audioRef.current.pause();
    
            this.setTime(this.state.percentPlayed);
            this.needToInitializeForIos = false;
        }
    }

    onResetSettingsToDefault = () => {
        if (!this.defaults) {
            return;
        }

        const { offset, bpm, startTime, endTime, playbackRate, isFiltering } = this.defaults;

        this.props.onOffsetChange(offset);
        this.props.onBpmChange(bpm);

        this.audioRef.current.playbackRate = playbackRate;

        this.setState({
            startTime,
            endTime,
            playbackRate,
            isFiltering
        });

        localforage.setItem('korio-start-time', startTime);
        localforage.setItem('korio-end-time', endTime);
        localforage.setItem('korio-playback-rate', playbackRate);
        localforage.setItem('korio-is-filtering', isFiltering);
    }

    onClickPlay = () => {
        if (!this.state.isPlaying) {
            this.onClickPlayInternal();

            this.setState({ isPlaying: true });
        } else {
            this.onDragStart();
        }
    }

    onClickPlayInternal = () => {
        this.audioRef.current.play();

        const timePerBeat = (this.state.eightCountMarkers[1] - this.state.eightCountMarkers[0]) / 8;
        const offsetTime = this.props.offset * this.props.duration;
        this.intervalId = setInterval(() => {
            const percentPlayed = this.audioRef.current.currentTime / this.audioRef.current.duration;
            const count = this.audioRef.current.currentTime >= offsetTime
                ? ((Math.floor((this.audioRef.current.currentTime - offsetTime) / timePerBeat) + 1) % 8) || 8
                : null;

            this.setState({ percentPlayed, count });
        }, 50);
    }

    onDragStart = () => {
        if (this.state.isPlaying) {
            this.onDragStartInternal();
            this.setState({ isPlaying: false });
        }
    };

    onDragStartInternal = () => {
        clearInterval(this.intervalId);
        this.audioRef.current.pause();
    }

    onDrag = (e, data) => {
        const percentPlayed = this.getPercentPlayedForDrag(data.x);
        const diff = Math.abs(percentPlayed - this.state.percentPlayed);

        if (diff > .001) {
            const count = getCount(percentPlayed * this.props.duration, this.state.eightCountMarkers);
            
            this.setState({ percentPlayed, count });
        }
    };

    onDragStop = (e, data) => {
        this.setTime(this.getPercentPlayedForDrag(data.x));
    }

    respondToKeyUp = (e) => {
        if (e.code === 'Space') {
            if (this.state.isPlaying) {
                this.onDragStart();
            } else {
                this.onClickPlay();
            }
        }
    }

    respondToKeyDown = (e) => {
        if (e.code === 'ArrowLeft') {
            if (this.state.isPlaying) {
                this.onDragStart();
            }

            this.onClickRewind();
        }

        if (e.code === 'ArrowRight') {
            if (this.state.isPlaying) {
                this.onDragStart();
            }

            this.onClickForward();
        }
    }

    setTime = (percentPlayed) => {
        const time = percentPlayed * this.props.duration;

        this.audioRef.current.currentTime = time;
    }

    getPercentPlayedForDrag = (x) => {
        const minPercent = this.state.startTime / this.props.duration;
        const maxPercent = this.state.endTime / this.props.duration;

        const percentPlayed = -1 * x / CANVAS_WIDTH;

        if (percentPlayed < minPercent) {
            return minPercent;
        }

        if (percentPlayed > maxPercent) {
            return maxPercent;
        }

        return percentPlayed;
    }

    onClickRewind = () => {
        this.onDragStart();

        const minPercent = this.state.startTime / this.props.duration;
        const newPercent = Math.max(this.state.percentPlayed - 1.1 / this.props.duration, minPercent);
        
        this.setTime(newPercent);
        this.setState({ percentPlayed: newPercent });
    }

    onClickForward = () => {
        this.onDragStart();

        const maxPercent = this.state.endTime / this.props.duration;
        const newPercent = Math.min(this.state.percentPlayed + 1.1 / this.props.duration, maxPercent);

        this.setTime(newPercent);
        this.setState({ percentPlayed: newPercent });
    }

    onClickSkipToStart = () => {
        this.onDragStart();

        const percentPlayed = this.state.startTime / this.props.duration;

        this.setTime(percentPlayed);
        this.setState({ percentPlayed });
    }

    onClickSkipToEnd = () => {
        this.onDragStart();

        const percentPlayed = this.state.endTime / this.props.duration;
        this.setTime(percentPlayed);
        this.setState({ percentPlayed });
    }

    onPlaybackRateChange = (playbackRate) => {
        this.onDragStart();
        this.audioRef.current.playbackRate = playbackRate;
        this.setState({ playbackRate });

        localforage.setItem('korio-playback-rate', playbackRate);
    }

    onFilteringChange = (isFiltering) => {
        this.onDragStart();
        this.needToInitializeForIos = true;

        URL.revokeObjectURL(this.state.url);
        const fileToUse = isFiltering ? this.props.filteredFile : this.props.file;

        const url = URL.createObjectURL(fileToUse);

        this.setState({ isFiltering, url });

        localforage.setItem('korio-is-filtering', isFiltering);
    }

    onStartTimeChange = (startTime) => {
        this.onDragStart();

        if (this.audioRef.current.currentTime < startTime) {
            this.audioRef.current.currentTime = startTime;

            this.setState({
                startTime,
                percentPlayed: startTime / this.props.duration,
                count: getCount(startTime, this.state.eightCountMarkers)
            });
        } else {
            this.setState({ startTime });
        }

        localforage.setItem('korio-start-time', startTime);
    }

    onEndTimeChange = (endTime) => {
        this.onDragStart();

        if (this.audioRef.current.currentTime > endTime) {
            this.audioRef.current.currentTime = endTime;

            this.setState({
                endTime,
                percentPlayed: endTime / this.props.duration,
                count: getCount(endTime, this.state.eightCountMarkers)
            });
        } else {
            this.setState({ endTime });
        }

        localforage.setItem('korio-end-time', endTime);
    }

    onOffsetChangeWrapper = (offset) => {
        this.onDragStart();

        clearTimeout(this.first1Timeout);

        const eightCountMarkers = getEightCountMarkers(this.props.bpm, offset, this.props.duration);
        const count = getCount(this.audioRef.current.currentTime, eightCountMarkers);

        this.setState({ 
            shouldShowFirst1: true,
            eightCountMarkers,
            count 
        });

        this.props.onOffsetChange(offset);

        this.first1Timeout = setTimeout(() => {
            this.setState({ shouldShowFirst1: false });
        }, 1000);
    }

    onBpmChangeWrapper = (bpm) => {
        this.onDragStart();
        
        clearTimeout(this.second1Timeout);

        const eightCountMarkers = getEightCountMarkers(bpm, this.props.offset, this.props.duration);
        const count = getCount(this.audioRef.current.currentTime, eightCountMarkers);

        this.setState({ 
            shouldShowSecond1: true,
            eightCountMarkers,
            count 
        });

        this.props.onBpmChange(bpm);

        this.second1Timeout = setTimeout(() => {
            this.setState({ shouldShowSecond1: false });
        }, 1000);
    }

    render() {
        const { duration, normalizedData, bpm, offset, fileName, onStartOverClick, filteredFile } = this.props;
        const { url, isPlaying, startTime, endTime, percentPlayed, isWaveDrawn, count, playbackRate, isFiltering, eightCountMarkers, shouldShowFirst1, shouldShowSecond1, loaded } = this.state;
        
        const currentTime = percentPlayed * duration;

        return (
            <div className='music-player-and-settings'>
                <div className='music-player-wrapper'>
                    <div className={`music-player ${isPlaying ? '' : 'paused'}`}>
                        <Overlay 
                            isPlaying={isPlaying}
                            currentTime={currentTime}
                            duration={duration}
                            count={count} />
                        <div className='controls-and-progress'>
                            <PlayerControls
                                isWaveDrawn={isWaveDrawn}
                                isPlaying={isPlaying} 
                                onClickPlay={this.onClickPlay}
                                onClickRewind={this.onClickRewind}
                                onClickForward={this.onClickForward}
                                onClickSkipToStart={this.onClickSkipToStart}
                                onClickSkipToEnd={this.onClickSkipToEnd} />
                            <ProgressDisplay
                                isWaveDrawn={isWaveDrawn}
                                setIsWaveDrawn={() => this.setState({ isWaveDrawn: true })}
                                isPlaying={isPlaying}
                                normalizedData={normalizedData} 
                                percentPlayed={percentPlayed}
                                onDragStart={this.onDragStart} 
                                onDrag={this.onDrag}
                                onDragStop={this.onDragStop}
                                bpm={bpm} 
                                duration={duration} 
                                offset={offset} 
                                startTime={startTime}
                                endTime={endTime}
                                first1={eightCountMarkers.length > 0 && eightCountMarkers[0]}
                                second1={eightCountMarkers.length > 1 && eightCountMarkers[1]}
                                shouldShowFirst1={shouldShowFirst1}
                                shouldShowSecond1={shouldShowSecond1}
                            />
                        </div>
                        <audio 
                            key={`key_${url}`} 
                            ref={this.audioRef} 
                            src={url}
                            autoPlay={true}
                            onEnded={this.resetLoopToStart} />
                    </div>
                </div>
                {loaded &&
                    <PlayerSettings
                        onClickSetStart={() => this.onStartTimeChange(currentTime)}
                        onClickSetEnd={() => this.onEndTimeChange(currentTime)}
                        currentTime={currentTime}
                        isPlaying={isPlaying}
                        fileName={fileName}
                        bpm={bpm} 
                        onBpmChange={this.onBpmChangeWrapper}
                        offset={offset}
                        onOffsetChange={this.onOffsetChangeWrapper}
                        duration={duration} 
                        startTime={startTime}
                        endTime={endTime}
                        onStartTimeChange={this.onStartTimeChange}
                        onEndTimeChange={this.onEndTimeChange}
                        playbackRate={playbackRate}
                        onPlaybackRateChange={this.onPlaybackRateChange}
                        isFiltering={isFiltering}
                        isFilteringDisabled={filteredFile === 'disabled'}
                        onFilteringChange={this.onFilteringChange}
                        onStartOverClick={onStartOverClick}
                        onResetSettingsToDefault={this.onResetSettingsToDefault} />
                }
            </div>
        );
    }   
}
import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import './FilePicker.scss';

export default function FilePicker({ onFilePicked }) {
    const [className, setClassName] = useState('');

    const onDrop = (files) => {
        onFilePicked(files[0]);
    };

    return (
        <Dropzone 
            onDrop={onDrop}
            onDragEnter={() => setClassName('drag-enter')}
            onDragLeave={() => setClassName('')}
            multiple={false}>
            {({getRootProps, getInputProps}) => (
                <div className={`dropzone ${className}`} {...getRootProps()}>
                    <input {...getInputProps()} />
                </div>
            )}
        </Dropzone>
    );
}
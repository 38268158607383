import React, {memo} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PlayerControls.scss';
import Repeatable from 'react-repeatable';

const MemoizedPlayerControls = memo(function PlayerControls({ isWaveDrawn, isPlaying, onClickPlay, onClickSkipToStart, onClickSkipToEnd, onClickRewind, onClickForward }) {
    const hiddenClass = !isWaveDrawn ? 'hidden' : '';

    return (
        <div className='player-controls'>
            <div className={`control skip-to-start-button ${hiddenClass}`} onClick={onClickSkipToStart}>
                <FontAwesomeIcon color="white" icon={'fast-backward'} size="2x" />
            </div>
            <Repeatable className={`control rewind-button ${hiddenClass}`} onPress={onClickRewind} onHold={onClickRewind}>
                <FontAwesomeIcon color="white" icon={'step-backward'} size="2x" />
            </Repeatable>
            <div className={`control play-button ${hiddenClass}`} onClick={onClickPlay}>
                { isPlaying
                    ? <FontAwesomeIcon color="white" icon={['far', 'pause-circle']} size="5x" />
                    : <FontAwesomeIcon color="white" icon={['far', 'play-circle']} size="5x" />
                }
            </div>
            <Repeatable className={`control forward-button ${hiddenClass}`} onPress={onClickForward} onHold={onClickForward}>
                <FontAwesomeIcon color="white" icon={'step-forward'} size="2x" />
            </Repeatable>
            <div className={`control skip-top-end-button ${hiddenClass}`} onClick={onClickSkipToEnd}>
                <FontAwesomeIcon color="white" icon={'fast-forward'} size="2x" />
            </div>
        </div>
    );
});

export default MemoizedPlayerControls;
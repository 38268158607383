import toWav from 'audiobuffer-to-wav';

// Adapted from https://github.com/JMPerez/karaoke/blob/gh-pages/scripts/main.js
export function createAudio(buffer, onFilteredFileCreated) {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    var isDesktopChrome = !isIOSChrome && isChromium !== null && typeof isChromium !== "undefined" && vendorName === "Google Inc." && isOpera === false && isIEedge === false;
    
    if (!isDesktopChrome) {
        onFilteredFileCreated('disabled');
        return;
    }

    window.OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
    var offlineCtx = new OfflineAudioContext(2, 44100 * buffer.duration, 44100);
    const source = offlineCtx.createBufferSource();
    source.buffer = buffer;

    if (offlineCtx.audioWorklet) {
        offlineCtx.audioWorklet.addModule('KaraokeProcessor.js').then(() => {
            const karaokeNode = new AudioWorkletNode(offlineCtx, 'karaoke-processor');
            
            source.connect(karaokeNode).connect(offlineCtx.destination);
            source.start();
    
            offlineCtx.oncomplete = function(e) {
                var wav = toWav(e.renderedBuffer); 
                const blob = new Blob( [ wav ], { type: 'audio/wav' } );
                
                onFilteredFileCreated(blob);
            };
    
            offlineCtx.startRendering();
        });
    } else {
        const processor = offlineCtx.createScriptProcessor(512 /*bufferSize*/ , 2 /*num inputs*/ , 1 /*num outputs*/);
        
        // based on https://gist.github.com/kevincennis/3928503
        // flip phase of right channel
        // http://www.soundonsound.com/sos/sep04/articles/qa0904-7.htm
        processor.onaudioprocess = (evt) => {
            var inputL = evt.inputBuffer.getChannelData(0),
                inputR = evt.inputBuffer.getChannelData(1),
                output = evt.outputBuffer.getChannelData(0);

            for (let i = 0; i < inputL.length; i++) {
                output[i] = inputL[i] - inputR[i];
            }
        };

        source.connect(processor).connect(offlineCtx.destination);
        source.start();
    
        offlineCtx.oncomplete = function(e) {
            var wav = toWav(e.renderedBuffer); 
            const blob = new Blob( [ wav ], { type: 'audio/wav' } );
            
            onFilteredFileCreated(blob);
        };

        offlineCtx.startRendering();
    }
}
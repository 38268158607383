import React, {PureComponent} from 'react';
import Repeatable from 'react-repeatable';

export default class RepeatableButton extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isBeingPressed: false
        };
    }

    render() {
        const { onClick, className, ...props } = this.props;

        return (
            <Repeatable
                className={`${className} ${this.state.isBeingPressed ? 'pressed' : ''}`}
                tag="button"
                type="button"
                onTouchStart={e => e.preventDefault()}
                onMouseDown={e => e.preventDefault()}
                onPress={() => {
                    this.setState({ isBeingPressed: true });
                }}
                onRelease={() => {
                    this.setState({ isBeingPressed: false });
                }}
                onClick={onClick}
                onHold={onClick}
                repeatInterval={100}
                {...props}
            />
        );
    }
}
import React, {memo} from 'react';
import TimePicker from './Pickers/TimePicker';
import { event } from 'react-ga';

const MemoizedLoopSettings = memo(function LoopSettings({ startTime, endTime, onStartTimeChange, onEndTimeChange, duration }) {
    const onStartTimeChangeWrapper = (startTime) => {
        event({
            category: "Setting",
            action: "Start time",
            value: startTime
        });

        onStartTimeChange(startTime);
    };

    const onEndTimeChangeWrapper = (endTime) => {
        event({
            category: "Setting",
            action: "End time",
            value: endTime
        });

        onEndTimeChange(endTime);
    };
    
    return (
        <div className='loop-settings'>
            <TimePicker
                className={'start-time'} 
                label={'Start'} 
                value={startTime} 
                onValueChange={onStartTimeChangeWrapper}
                max={endTime}
                min={-1} />
            <TimePicker
                className={'end-time'} 
                label={'End'} 
                value={endTime} 
                onValueChange={onEndTimeChangeWrapper}
                max={duration + 1} 
                min={startTime} />
        </div>
    );
});

export default MemoizedLoopSettings;
// TODO consolidate with getUpdatedCountLines
export const getEightCountMarkers = (bpm, offset, duration) => {
    const trueDuration = (1 - offset) * duration;
    const numBeats = bpm * duration / 60;
    const incrementalPercent = trueDuration / numBeats;
    const offsetTime = offset * duration;

    const markers = [];
    for (let i = 0; i < numBeats; i += 8) {
        markers.push(i * incrementalPercent + offsetTime);
    }

    return markers;
};

export const getCount = (currentTime, eightCountMarkers) => {
    if (eightCountMarkers.length < 2 || currentTime < eightCountMarkers[0]) {
        return null;
    }

    const incrementalTime = eightCountMarkers[1] - eightCountMarkers[0];
    const currentEightCountIndex = eightCountMarkers.findIndex(m => m > currentTime) - 1;

    const count = ((currentTime - eightCountMarkers[currentEightCountIndex]) / incrementalTime * 8) + 1;

    return Math.floor(count);
};

export function str_pad_left(string,pad,length) {
    return (new Array(length+1).join(pad)+string).slice(-length);
}

export const convertToTime = (numSeconds) => {
    const minutes = Math.floor(numSeconds / 60) % 60;
    const seconds = Math.floor(numSeconds - minutes * 60);
    
    return minutes + ':' + str_pad_left(seconds,'0',2)
}
export const animateIntro = (o2, kWrapper, k1, k2, r, o1, i, intro, onIntroComplete) => {
    setTimeout(() => { morphPlayToK(kWrapper, k1, k2) }, 1000);
    setTimeout(() => { separateOandK(o2, kWrapper) }, 2250);
    setTimeout(() => fadeIn(r), 2500);
    setTimeout(() => fadeIn(o1), 2750);
    setTimeout(() => fadeIn(i), 2750);
    setTimeout(() => makeNeon(k1, k2, o1, r, i, o2), 3250);
    setTimeout(() => moveToTop(intro), 4000);

    setTimeout(() => onIntroComplete(), 5000);
};

const morphPlayToK = (kWrapper, k1, k2) => {
    kWrapper.classList.add('rotate');
    k1.classList.add('morph');
    k2.classList.add('morph');
};

const separateOandK = (o, kWrapper) => {
    o.classList.add('slide-right');
    kWrapper.classList.add('slide-left')
};

const fadeIn = (x) => {
    x.classList.add('fade-in');
};

const makeNeon = (k1, k2, o1, r, i, o2) => {
    k1.classList.add('neon');
    k2.classList.add('neon');
    o1.classList.add('neon');
    r.classList.add('neon');
    i.classList.add('neon');
    o2.classList.add('neon');
};

const moveToTop = (intro) => {
    intro.classList.add('move-to-top');
};
import React, { useEffect, useRef } from 'react';
import './Intro.scss';
import { animateIntro } from './IntroUtilities';

export default function Intro({ onIntroComplete }) {
    const o2Ref = useRef(null);
    const kWrapperRef = useRef(null);
    const k1Ref = useRef(null);
    const k2Ref = useRef(null);
    const rRef = useRef(null);
    const o1Ref = useRef(null);
    const iRef = useRef(null);
    const introRef = useRef(null);

    useEffect(() => {
        animateIntro(o2Ref.current, kWrapperRef.current, k1Ref.current, k2Ref.current, rRef.current, o1Ref.current, iRef.current, introRef.current, onIntroComplete);
    }, [onIntroComplete]);

    return (
        <div ref={introRef} className='intro'>
            <div className='korio-letters'>
                <span ref={o2Ref} className='korio-letter o2' />
                <span ref={kWrapperRef} className='k-wrapper'>
                    <span className='korio-letter k'>
                        <span ref={k1Ref} className='k1'>
                            I
                        </span>
                        <span ref={k2Ref} className='k2'>
                            V
                        </span>
                    </span>
                </span>
                <span ref={o1Ref} className='korio-letter o1' />
                <span ref={rRef} className='korio-letter r'>R</span>
                <span ref={iRef} className='korio-letter i'>I</span>
            </div>
        </div>
    );
}
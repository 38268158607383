import { analyze } from 'web-audio-beat-detector';
import { NUM_SAMPLES } from '../Common/Constants';
import { createAudio } from './KaraokeUtilities';

export const analyzeFile = (file, onBpmDetected, onDataAnalyzed, onOffsetChange, onFilteredFileCreated, onDurationSet) => {
    // adapted from https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    const audioContext = new AudioContext({ sampleRate: 44100 });

    // For local file:
    const fileReader = new FileReader();

    fileReader.onload = (f) => {
        const arrayBuffer = f.target.result;

        audioContext.decodeAudioData(arrayBuffer, (buffer) => {
            const roundedDuration = Math.round(buffer.duration * 100) / 100
            onDurationSet(roundedDuration);

            detectCounts(buffer, onBpmDetected);
            analyzeData(buffer, onDataAnalyzed, onOffsetChange);
            createAudio(buffer, onFilteredFileCreated);
        });
    }

    fileReader.readAsArrayBuffer(file);
    
    // For soundcloud:
    // fetch('https://soundcloud.com/miami-nights-1984/accelerated')
    //     .then(response => response.arrayBuffer())
    //     .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer, (buffer) => {
    //         detectCounts(buffer, onBpmDetected);
    //         analyzeData(buffer, onDataAnalyzed);
    //     }));

};

const detectCounts = (audioBuffer, onBpmDetected) => {
    analyze(audioBuffer)
        .then((bpm) => {
            // TODO This seems to be different when run on my phone.. consider switching libraries
            const roundedBpm = Math.round(bpm);
            onBpmDetected(roundedBpm);
        })
        .catch((err) => {
            console.log('bpm error', err);
        });
};

const analyzeData = (audioBuffer, onDataAnalyzed, onOffsetChange) => {
    const filteredData = filterData(audioBuffer);
    const normalizedData = normalizeData(filteredData);
    const offsetIndex = getOffset(normalizedData);

    onDataAnalyzed(normalizedData);
    onOffsetChange(offsetIndex);
};

const filterData = (audioBuffer) => {
    const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
    const samples = NUM_SAMPLES; // Number of samples we want to have in our final data set
    const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
    const filteredData = [];
    
    for (let i = 0; i < samples; i++) {
        let blockStart = blockSize * i; // the location of the first sample in the block
        let sum = 0;
        
        for (let j = 0; j < blockSize; j++) {
            sum = sum + Math.abs(rawData[blockStart + j]) // find the sum of all the samples in the block
        }

        filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
    }

    return filteredData;
};

const normalizeData = (filteredData) => {
    const multiplier = Math.pow(Math.max(...filteredData), -1);
    return filteredData.map(n => n * multiplier);
};

const getOffset = (normalizedData) => {
    const index = normalizedData.findIndex((d) => d > 0.01);

    return index/normalizedData.length;
};
import React, {memo} from 'react';
import NumberPicker from './Pickers/NumberPicker';
import { event } from 'react-ga';

const MemoizedCountSettings = memo(function CountSettings({ bpm, onBpmChange, offset, onOffsetChange, duration }) {
    const convertOffsetToSeconds = (offset) => {
        return offset * duration;
    };

    const convertSecondsToOffset = (seconds) => {
        const newOffset = seconds / duration;

        event({
            category: "Setting",
            action: "Offset",
            value: seconds
        });

        onOffsetChange(newOffset);
    }

    const onBpmChangeWrapper = (bpm) => {
        event({
            category: "Setting",
            action: "BPM",
            value: bpm
        });

        onBpmChange(bpm);
    };

    return (
        <div className='count-settings'>
            <NumberPicker
                className={'offset'} 
                label={'Offset'} 
                value={convertOffsetToSeconds(offset)} 
                onValueChange={convertSecondsToOffset} 
                min={-10} 
                step={0.1} 
                decimalPlaces={1}
                suffix={'s'} />
            <NumberPicker
                className={'bpm'} 
                label={'BPM'} 
                value={bpm} 
                onValueChange={onBpmChangeWrapper} 
                min={1} 
                step={1} 
                decimalPlaces={0} />
        </div>
    );
});

export default MemoizedCountSettings;
import React, { PureComponent, createRef } from 'react';
import './App.scss';
import { analyzeFile } from './Analyzer/AnalyzerUtilities';
import MusicPlayer from './MusicPlayer/MusicPlayer';
import FilePicker from './FilePicker/FilePicker';
import Intro from './Intro/Intro';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faCheckSquare, faAngleUp, faAngleDown, faFileUpload, faMusic, faFastBackward, faFastForward, faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle, faSquare, faPauseCircle } from '@fortawesome/free-regular-svg-icons';
import Icon from './Icon/Icon';
import localforage from 'localforage';
import { initialize, pageview } from 'react-ga';
import WelcomeOverlay from './WelcomeOverlay';
import GettingStartedOverlay from './GettingStartedOverlay';

const trackingId = "UA-160592407-1";
initialize(trackingId);
pageview(window.location.pathname);

library.add(faBars, faPauseCircle, faCheckSquare, faSquare, faAngleUp, faAngleDown, faFileUpload, faMusic, faPlayCircle, faFastBackward, faFastForward, faStepForward, faStepBackward);

const defaultState = {
    normalizedData: null,
    file: null,
    filteredFile: null,
    bpm: null,
    offset: null,
    isIntroComplete: false,
    duration: null,
    shouldShowWelcome: true,
    shouldShowGettingStarted: true
};

export default class App extends PureComponent {
    state = defaultState;

    constructor() {
        super();

        this.appRef = createRef();
    }

    componentDidMount() {
        this.updateWindowDimensions();

        window.addEventListener('resize', this.updateWindowDimensions);
        document.addEventListener('touchmove', this.preventZoom, { passive: false });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        document.removeEventListener('touchmove', this.preventZoom, { passive: false });
    }

    updateWindowDimensions = () => {
        const height = window.innerHeight;

        this.appRef.current.style.height = `${height}px`;
        this.appRef.current.style.paddingTop = `${height*0.15}px`;
    }

    preventZoom = (event) => {
        event.preventDefault(); 
    }

    onBpmChange = (bpm) => {
        localforage.setItem('korio-bpm', bpm);

        this.setState({ bpm })
    }

    onDataAnalyzed = (normalizedData) => {
        localforage.setItem('korio-normalized-data', normalizedData);

        this.setState({ normalizedData });
    }

    onOffsetChange = (offset) => {
        localforage.setItem('korio-offset', offset);

        this.setState({ offset });
    }

    onFilteredFileCreated = (filteredFile) => {
        localforage.setItem('korio-filtered-file', filteredFile);

        this.setState({ filteredFile });
    }

    onDurationSet = (duration) => {
        localforage.setItem('korio-duration', duration);

        this.setState({ duration });
    }

    onFilePicked = (file) => {
        localforage.setItem('korio-file', file);

        this.setState({ file });

        analyzeFile(file, this.onBpmChange, this.onDataAnalyzed, this.onOffsetChange, this.onFilteredFileCreated, this.onDurationSet);
    }

    onIntroComplete = async () => {
        try {
            const file = await localforage.getItem('korio-file');
            const filteredFile = await localforage.getItem('korio-filtered-file');
            const normalizedData = await localforage.getItem('korio-normalized-data');
            const bpm = await localforage.getItem('korio-bpm');
            const offset = await localforage.getItem('korio-offset');
            const duration = await localforage.getItem('korio-duration');
            const shouldShowOverlays = !file && !filteredFile && !normalizedData && bpm === null && offset === null && duration === null;
            
            this.setState({ 
                file, 
                filteredFile, 
                normalizedData, 
                bpm, 
                offset, 
                duration,
                isIntroComplete: true,
                shouldShowWelcome: shouldShowOverlays,
                shouldShowGettingStarted: shouldShowOverlays
            });
        } catch (err) {
            console.log('loading error', err);
            this.setState({ isIntroComplete: true });
        }
    }

    emptyOnClick = () => {
        // We need an empty on click handler so browser will respect touch-action: manipulation in css
    }

    getFileName = () => {
        if (this.state.file) {
            return this.state.file.name.replace(/\.[^/.]+$/, "");
        }

        return null;
    }

    onStartOverClick = () => {
        localforage.clear();

        this.setState({
            ...defaultState,
            isIntroComplete: true,
            shouldShowWelcome: false,
            shouldShowGettingStarted: false
        });
    }

    render() {
        const { normalizedData, bpm, offset, file, filteredFile, isIntroComplete, duration, shouldShowWelcome, shouldShowGettingStarted } = this.state;
        const isAnalyzed = !!normalizedData && bpm !== null && offset !== null && !!filteredFile && !!file && duration !== null;
        const fileName = this.getFileName();
        let mainContent = null;

        if (!file) {
            mainContent = <FilePicker onFilePicked={this.onFilePicked} />;
        } else if (isAnalyzed) {
            mainContent = <MusicPlayer 
                            key={file.name}
                            fileName={fileName}
                            duration={duration}
                            file={file}
                            filteredFile={filteredFile}
                            normalizedData={normalizedData} 
                            bpm={bpm} 
                            onBpmChange={this.onBpmChange}
                            offset={offset} 
                            onOffsetChange={this.onOffsetChange} 
                            onStartOverClick={this.onStartOverClick}/>;
        } 

        return (
            <>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <div ref={this.appRef} className="korio-app" onClick={this.emptyOnClick}>
                    <Intro onIntroComplete={this.onIntroComplete} />
                    <div className='main-content-wrapper'>
                        { isIntroComplete && 
                            <>
                                { mainContent }
                                { !isAnalyzed && <Icon hasFile={!!file} onTryAgain={this.onStartOverClick} /> }
                            </>
                        }
                    </div>
                    { isIntroComplete && shouldShowWelcome && 
                        <WelcomeOverlay onClose={() => this.setState({ shouldShowWelcome: false })} /> 
                    }
                    { isAnalyzed && shouldShowGettingStarted && 
                        <GettingStartedOverlay onClose={() => this.setState({ shouldShowGettingStarted: false })} /> 
                    }
                </div>
            </>
        );
    }
}

import React from 'react';

export default function WelcomeOverlay({ onClose }) {
    return (
        <div className='overlay'>
            <div className='modal'>
                <div className='modal-title'>Getting Started</div>
                <div className='modal-subtitle'>Setting the counts</div>
                <div className='modal-text'>
                    <div className='modal-subheader'>We've set the counts for you!</div>
                    <div>If they're incorrect, you can try tweaking them a bit.</div>
                    <div>First, adjust the <b>offset</b> to line up the first 1-count.</div>
                    <div>Then, adjust the <b>BPM</b> to line up the second 1-count,</div>
                    <div>and you should be good to go!</div> 
                </div>
                <button className='close-button' onClick={onClose}>
                    Got it
                </button>
            </div>
        </div>
    );
}
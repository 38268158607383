import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Checkbox({ className, label, isChecked, onCheck }) {
    return (
        <div className={`picker-wrapper ${className}`}>
            <div className='label'>
                {label}
            </div>
            <div className='picker'>
                <div className='checkbox' onClick={() => onCheck(!isChecked)}>
                    <div className='beta'>BETA</div>
                    <div>Remove</div>
                    <div>vocals</div>
                    { isChecked 
                        ? <FontAwesomeIcon color="white" icon={'check-square'} size="2x" />
                        : <FontAwesomeIcon color="white" icon={['far', 'square']} size="2x" />
                    }
                </div>
            </div>
        </div>
    );
}
import React, {memo} from 'react';
import './Pickers.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertToTime, str_pad_left } from '../../MusicPlayerUtilities';
import RepeatableButton from '../../../Common/RepeatableButton';

const MemoizedTimePicker = memo(function TimePicker({ className, label, value, onValueChange, max, min }) {
    const time = convertToTime(value).split(':');
    const minuteValue = time[0];
    const secondValue = time[1];

    const parsedMinuteValue = parseInt(minuteValue);
    const parsedSecondValue = parseInt(secondValue);

    const onMinuteIncrement = () => {
        const newMinute = parsedMinuteValue + 1;
        const newValue = newMinute * 60 + parseInt(secondValue);

        if (newValue >= max) {
            onValueChange(max - 1);
        } else {
            onValueChange(newValue);
        }
    }

    const onMinuteDecrement = () => {
        const newMinute = parsedMinuteValue - 1;
        const newValue = newMinute * 60 + parseInt(secondValue);

        if (newValue <= min) {
            onValueChange(min + 1);
        } else {
            onValueChange(newValue);
        }
    }

    const onSecondIncrement = () => {
        const newSecond = parsedSecondValue + 1;
        const newValue = parseInt(minuteValue) * 60 + newSecond;

        onValueChange(newValue);
    }

    const onSecondDecrement = () => {
        const newSecond = parsedSecondValue - 1;
        const newValue = parseInt(minuteValue) * 60 + newSecond;

        onValueChange(newValue);
    }

    const isMinuteAtMax = (minute, second) => {
        return (minute + 1) * 60 + second >= max
    }

    const isMinuteAtMin = (minute, second) => {
        return (minute - 1) * 60 + second <= min;
    }

    const isSecondAtMax = (minute, second) => {
        return minute * 60 + (second + 1) >= max;
    }

    const isSecondAtMin = (minute, second) => {
        return minute * 60 + (second - 1) <= min;
    }

    const isIncrementMinuteDisabled = isMinuteAtMax(parsedMinuteValue, parsedSecondValue) 
    const isDecrementMinuteDisabled = isMinuteAtMin(parsedMinuteValue, parsedSecondValue);

    const isIncrementSecondDisabled = isSecondAtMax(parsedMinuteValue, parsedSecondValue);
    const isDecrementSecondDisabled = isSecondAtMin(parsedMinuteValue, parsedSecondValue);

    const formattedSecondValue = str_pad_left(secondValue,'0',2);

    return (
        <div className={`picker-wrapper ${className}`}>
            <div className='label'>
                {label}
            </div>
            <div className='time-picker-wrapper'>
                <div className='picker time-picker minute-picker'>
                    <RepeatableButton className={`increment ${isIncrementMinuteDisabled ? 'disabled' : ''}`} onClick={isIncrementMinuteDisabled ? undefined : onMinuteIncrement}>
                        <FontAwesomeIcon color="white" icon={'angle-up'} size="2x" />
                    </RepeatableButton>
                    <p className='number-display'>
                        {minuteValue}
                    </p>
                    <RepeatableButton className={`decrement ${isDecrementMinuteDisabled ? 'disabled' : ''}`} onClick={isDecrementMinuteDisabled ? undefined : onMinuteDecrement}>
                        <FontAwesomeIcon color="white" icon={'angle-down'} size="2x" />
                    </RepeatableButton>
                </div>
                <span className='colon'>
                    :
                </span>
                <div className='picker time-picker'>
                    <RepeatableButton className={`increment ${isIncrementSecondDisabled ? 'disabled' : ''}`} onClick={isIncrementSecondDisabled ? undefined : onSecondIncrement}>
                        <FontAwesomeIcon color="white" icon={'angle-up'} size="2x" />
                    </RepeatableButton>
                    <p className='number-display'>
                        {formattedSecondValue}
                    </p>
                    <RepeatableButton className={`decrement ${isDecrementSecondDisabled ? 'disabled' : ''}`} onClick={isDecrementSecondDisabled ? undefined : onSecondDecrement}>
                        <FontAwesomeIcon color="white" icon={'angle-down'} size="2x" />
                    </RepeatableButton>
                </div>
            </div>
        </div>
    );
});

export default MemoizedTimePicker;
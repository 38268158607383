import React, { useRef, useEffect } from 'react';
import './ProgressDisplay.scss';
import { drawWave, drawCounts } from './ProgressDisplayUtilities';
import { CANVAS_WIDTH } from '../../Common/Constants';
import Draggable from 'react-draggable';

export default function ProgressDisplay({ startTime, endTime, onDragStop, isWaveDrawn, setIsWaveDrawn, isPlaying, normalizedData, onDragStart, onDrag, percentPlayed, setPercentPlayed, bpm, duration, offset, first1, second1, shouldShowFirst1, shouldShowSecond1 }) {
    const waveCanvasRef = useRef(null);
    const countCanvasRef = useRef(null);

    useEffect(() => {
        drawWave(normalizedData, waveCanvasRef, setIsWaveDrawn);
    }, [normalizedData, setIsWaveDrawn]);

    useEffect(() => {
        drawCounts(bpm, offset, duration, countCanvasRef)
    }, [bpm, offset, duration]);

    const calculatedDistanceMoved = -1 * CANVAS_WIDTH * Math.max(percentPlayed, startTime / duration);
    const startX = CANVAS_WIDTH * startTime / duration;
    const endX = CANVAS_WIDTH * endTime / duration;
    const offsetX = first1 ? CANVAS_WIDTH * first1 / duration : 0;
    const bpmX = second1 ? CANVAS_WIDTH * second1 / duration : 0;

    return (
        <div className={`progress-display ${isPlaying || !isWaveDrawn ? '' : 'paused'}`}>
            <Draggable
                axis="x"
                handle=".canvas"
                position={{ x: calculatedDistanceMoved, y: 0 }}
                onStart={onDragStart}
                onDrag={onDrag}
                onStop={onDragStop}>
                    <div>
                        <canvas ref={waveCanvasRef} className={'canvas canvas-wave'} style={{ width: CANVAS_WIDTH }} />
                        <canvas ref={countCanvasRef} className={'canvas canvas-counts'} style={{ width: CANVAS_WIDTH }} />
                        <div style={{ left: startX }} className={`start-loop-marker ${percentPlayed === 0 ? 'hidden' : ''}`} />
                        <div style={{ left: endX }} className={`end-loop-marker ${percentPlayed === 1 ? 'hidden' : ''}`} />
                        <div style={{ left: (offsetX - 20) }} className={`offset-count-marker ${shouldShowFirst1 ? '' : 'hidden'}`}>1st 1</div>
                        <div style={{ left: (bpmX - 20) }} className={`bpm-count-marker ${shouldShowSecond1 ? '' : 'hidden'}`}>2nd 1</div>
                    </div>
            </Draggable>
        </div>
    );
}
import React, {memo} from 'react';
import NumberPicker from './Pickers/NumberPicker';
import Checkbox from './Checkbox';
import { event } from 'react-ga';

const MemoizedEffectSettings = memo(function EffectSettings({ isFilteringDisabled, playbackRate, onPlaybackRateChange, isFiltering, onFilteringChange }) {    
    const onPlaybackRateChangeWrapper = (value) => {
        event({
            category: "Setting",
            action: "Playback rate",
            value
        });

        onPlaybackRateChange(value / 100)
    };

    const onFilteringChangeWrapper = (isFiltering) => {
        event({
            category: "Setting",
            action: "Filter"
        });

        onFilteringChange(isFiltering);
    };
    
    return (
        <div className='effect-settings'>
            <NumberPicker
                className={'speed'} 
                label={'Speed'} 
                value={playbackRate * 100} 
                onValueChange={onPlaybackRateChangeWrapper} 
                min={1} 
                step={1} 
                decimalPlaces={0} 
                suffix={'%'} />
            <Checkbox 
                className={`filter ${isFilteringDisabled ? 'disabled' : ''}`}
                label='Filter'
                isChecked={isFiltering}
                onCheck={isFilteringDisabled ? () => undefined : onFilteringChangeWrapper}
            />
        </div>
    );
});

export default MemoizedEffectSettings;
import React, {memo, useLayoutEffect, useRef, useState} from 'react';
import './PlayerSettings.scss';
import SwipeableViews from 'react-swipeable-views';
import CountSettings from './CountSettings';
import LoopSettings from './LoopSettings';
import EffectSettings from './EffectSettings';
import { event } from 'react-ga';
import Popover from 'react-tiny-popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertToTime } from '../MusicPlayerUtilities';
import { useEffect } from 'react';

const MemoizedPlayerSettings = memo(function PlayerSettings({ onClickSetStart, onClickSetEnd, currentTime, onResetSettingsToDefault, isPlaying, isFilteringDisabled, fileName, bpm, onBpmChange, offset, onOffsetChange, duration, startTime, endTime, onStartTimeChange, onEndTimeChange, playbackRate, onPlaybackRateChange, isFiltering, onFilteringChange, onStartOverClick }) {
    const settingsRef = useRef(null);
    const [activeTab, setActiveTab] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [modalActionId, setModalActionId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useLayoutEffect(() => {
        const updateWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [settingsRef]);

    useEffect(() => {
        document.addEventListener('keydown', closeModal);

        return () => {
            document.removeEventListener('keydown', closeModal);
        }
    }, [isPlaying]);

    const closeModal = () => {
        setIsModalOpen(false);

        // closing menu as well doesn't seem to work, probably because Popover has internal state
    };

    const onStartOverConfirmWrapper = () => {
        event({
            category: "Clear",
            action: "Start over button",
        });
        
        onStartOverClick();
    };

    const onResetSettingsConfirmWrapper = () => {
        setIsModalOpen(false);

        event({
            category: "Clear",
            action: "Reset settings button",
        });
        
        onResetSettingsToDefault();
    };

    const countSettings = <CountSettings
                            bpm={bpm}
                            onBpmChange={onBpmChange}
                            offset={offset}
                            onOffsetChange={onOffsetChange}
                            duration={duration}
                        />;
    
    const loopSettings = <LoopSettings
                            startTime={startTime}
                            endTime={endTime}
                            onStartTimeChange={onStartTimeChange}
                            onEndTimeChange={onEndTimeChange}
                            duration={duration}
                        />;

    const effectSettings = <EffectSettings
                            playbackRate={playbackRate}
                            onPlaybackRateChange={onPlaybackRateChange}
                            isFiltering={isFiltering}
                            isFilteringDisabled={isFilteringDisabled}
                            onFilteringChange={onFilteringChange}
                        />;

    const onClickNewSong = () => {
        setModalActionId(1);
        setIsModalOpen(true);
        setIsMenuOpen(false);
    };

    const onClickResetSettings = () => {
        setModalActionId(0);
        setIsModalOpen(true);
        setIsMenuOpen(false);
    };

    const formattedCurrentTime = convertToTime(currentTime);
    const isSetStartDisabled = Math.floor(currentTime) === Math.floor(endTime);
    const isSetEndDisabled = Math.floor(currentTime) === Math.floor(startTime);

    const onClickSetStartWrapper = () => {
        if (isSetStartDisabled) {
            return undefined;
        }

        setIsMenuOpen(false);
        onClickSetStart();
    }

    const onClickSetEndWrapper = () => {
        if (isSetEndDisabled) {
            return undefined;
        }

        setIsMenuOpen(false);
        onClickSetEnd();
    }

    const menu = (
        <div className={`menu ${isPlaying ? 'hidden' : ''}`}>
            <div className={`option ${isSetStartDisabled ? 'disabled' : ''}`} onClick={onClickSetStartWrapper}>
                Set start to {formattedCurrentTime}
            </div>
            <div className={`option ${isSetEndDisabled ? 'disabled' : ''}`} onClick={onClickSetEndWrapper}>
                Set end to {formattedCurrentTime}
            </div>
            <div className='option' onClick={onClickResetSettings}>
                Reset settings
            </div>
            <div className='option' onClick={onClickNewSong}>
                New song
            </div>
        </div>
    );

    return (
        <div ref={settingsRef} className='player-settings'>
            <div className='settings-header'>
                <div className='file-name'>
                    {fileName}
                </div>
                <Popover 
                    isOpen={isMenuOpen}
                    padding={18}
                    position={'left'} // preferred position
                    content={menu}
                    onClickOutside={() => setIsMenuOpen(false)}>
                    <button className={`menu-button ${isMenuOpen ? 'open' : ''} ${isPlaying ? 'hidden' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <FontAwesomeIcon color="black" icon={'bars'} />
                    </button>
                </Popover>
            </div>
            {isModalOpen &&
                <div className='modal-overlay'>
                    <div className='modal'>
                        <div className='modal-title'>Are you sure?</div>
                        <div className='modal-subtitle'>{
                            modalActionId === 1
                                ? 'This will start a new project.'
                                : 'This will clear your settings.'
                        }</div>
                        <div className='modal-buttons'>
                            <button className='no-button' onClick={() => setIsModalOpen(false)}>
                                No, I take it back
                            </button>
                            <button className='yes-button' onClick={
                                modalActionId === 1
                                    ? onStartOverConfirmWrapper
                                    : onResetSettingsConfirmWrapper
                            }>
                                Yes, I'm sure
                            </button>
                        </div>
                    </div>
                </div>
            }
            { screenWidth < 960
                ? <div className={`swipeable-tabs-container ${isPlaying ? 'hidden' : ''}`}>
                    <SwipeableViews className='swipeable-views' index={activeTab} onChangeIndex={(index) => setActiveTab(index)}>
                        {countSettings}
                        {loopSettings}
                        {effectSettings}
                    </SwipeableViews>
                    <div className='tab-bar'>
                        <div 
                            className={`tab ${activeTab === 0 ? 'selected' : ''}`}
                            onClick={() => setActiveTab(0)}>
                            Count
                        </div>
                        <div 
                            className={`tab ${activeTab === 1 ? 'selected' : ''}`}
                            onClick={() => setActiveTab(1)}>
                            Loop
                        </div>
                        <div 
                            className={`tab ${activeTab === 2 ? 'selected' : ''}`}
                            onClick={() => setActiveTab(2)}>
                            Effects
                        </div>
                    </div>
                </div>
                : <div className={`static-tabs-container ${isPlaying ? 'hidden' : ''}`}>
                    {countSettings}
                    {loopSettings}
                    {effectSettings}
                </div>
            }  
        </div>
    );
});

export default MemoizedPlayerSettings;
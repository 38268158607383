import React from 'react';
import { convertToTime } from '../MusicPlayerUtilities';
import './Overlay.scss';

export default function Overlay({ isPlaying, currentTime, duration, count }) {
    return (
        <div className={`player-overlay ${isPlaying ? '' : 'paused'}`}>
            <div className='section left'>
                <div className={`count-section ${isPlaying ? 'large' : ''}`}>
                    <div className='value'>
                        {count >= 1 ? count : '-'}
                    </div>
                    <div className='label'>
                        Count
                    </div>
                </div>
            </div>
            <div className={'start-marker'} />
            <div className='time-section'>
                <div className='time'>
                    {convertToTime(currentTime)}
                </div>
                <div className='slash'> 
                    / 
                </div>
                <div className='duration'>
                    {convertToTime(duration)}
                </div>
            </div>
        </div>
    )
}